import './style.css'
import * as THREE from 'three'

// loading
const textureloader = new THREE.TextureLoader()
const normalTexture = textureloader.load('textures/height_normal.png')

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Objects
const sphereGeometry = new THREE.SphereBufferGeometry( .5, 64, 64);

// Materials
const material = new THREE.MeshStandardMaterial()
material.color = new THREE.Color(0x888888)
material.metalness = 0.7
material.roughness = 0.2
material.normalMap = normalTexture

// Mesh
const sphere = new THREE.Mesh(sphereGeometry, material)
scene.add(sphere)

// Lights
const pointLightBase = new THREE.PointLight(0xffffff, 0.1)
pointLightBase.position.x = 3
pointLightBase.position.y = 3
pointLightBase.position.z = 3
scene.add(pointLightBase)

const pointLightBlue = new THREE.PointLight(0x00efff, 10)
pointLightBlue.position.x = -2
pointLightBlue.position.y = 1.5
pointLightBlue.position.z = -.5
scene.add(pointLightBlue)


const pointLightRed = new THREE.PointLight(0xff0000, 4)
pointLightRed.position.x = 2
pointLightRed.position.y = -1
pointLightRed.position.z = -2
scene.add(pointLightRed)

// animate
document.addEventListener('mousemove', onDocumentMouseMove)

let mouseX = 0
let mouseY = 0

let targetX = 0
let targetY = 0

const windowHalfX = window.innerWidth / 2
const windowHalfY = window.innerHeight / 2

function onDocumentMouseMove(event) {
    mouseX = (event.clientX - windowHalfX)
    mouseY = (event.clientY - windowHalfY)
}

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 2
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true

})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */

const clock = new THREE.Clock()

const tick = () =>
{


    targetX = 0.001 * mouseX
    targetY = 0.001 * mouseY

    const elapsedTime = clock.getElapsedTime()

    // Update objects
    sphere.rotation.y = .5 * elapsedTime

    sphere.rotation.x += .05 * (targetY - sphere.rotation.x)
    sphere.rotation.y += .5 * (targetX - sphere.rotation.y)
    sphere.position.z += -.05 * (targetY- sphere.rotation.x)

    // Update Orbital Controls
    // controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()